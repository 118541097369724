<template>
  <div>
    <p>焱十一服务规则 第一条：总则
      依据《焱十一用户注册协议》注册的用户，在同意本规则以下全部条款后，方有资格登录焱十一，并享有我们提供的网络竞拍服务（以下简称“服务”）。您使用我们提供的服务即意味着同意受本规则约束，使用服务前请认真阅读本规则。
      第二条：目的及适用主体 为维护在焱十一进行的竞拍活动的秩序，规范参与拍卖活动的各方的行为，保障用户的合法权益，特制定本规则。 本规则适用于通过焱十一进行在线拍卖的各方主体。 第三条：名词解释
      “我们”或“艺得在线”：指北京艺得在线科技有限公司。 焱十一：由北京艺得在线科技有限公司开发并运营的网络拍卖服务平台，包括的官方网站（www.easyebid.com）、APP、微信公众号/服务号/视频号、微博客户端、抖音号等。
      拍卖公司：指境内外具有从事拍卖活动相关资质，且在焱十一注册，并与艺得在线签署书面合作合同的拍卖公司、拍卖行和其他组织，以下统称为“拍卖公司”。
      竞买人：拥有独立的焱十一帐号，同意本规则、《竞拍须知》、《免责声明》及拍卖公司的拍卖规则、与支付相关的协议等同类文件，且按照拍卖公司要求足额交纳竞拍保证金后，在拍卖活动中具有竞拍资格的用户，在本规则中，也称为“用户”或“您”。
      买受人：竞买人在已结束的拍卖会中出价最高，且不低于拍卖公司规定的最低竞价或保留价的，并被本场拍卖会的拍卖师确定为竞买成功的，则该竞买人为该件拍卖标的的买受人。在本规则中，也称为“用户”或“您”。
      直播竞拍：拍卖公司通过焱十一直播拍卖会，使竞买人得以通过焱十一参与网上直播的拍卖会并以网络实时出价或网络预约出价的方式参与竞拍。
      委托代拍：对于没有进行网络直播的拍卖会，拍卖公司通过焱十一在网络上展示拍卖标的，竞买人可以通过焱十一委托出价的方式委托焱十一代拍。
      电话竞拍、线下代拍：对于不方便在网络上进行操作，或拍卖公司没有在焱十一展示拍卖标的的，用户可联系专属客服进行电话竞拍或线下代拍，具体操作方式以艺得在线客服解释为准。
      保证金：拍卖公司在通过焱十一开展的竞拍活动要求竞买人交纳相应保证金，以使得竞买人取得竞拍资格。关于保证金的交纳时间、交纳比例等，由拍卖公司自行确定，用户应以拍卖会页面的提示内容为准。
      拍卖标的：由拍卖公司在焱十一展示的，可供竞买人竞拍的商品。拍卖标的的描述是指对拍卖标的的作者、来历、日期、年代、尺寸、材料、出处、保存和估价等提供的说明文字和图片、视频、数据等材料。
      预约出价：在直播竞拍场次，竞买人可在拟竞拍的拍卖标的开拍前，在该拍卖标的的拍品详情页面中进行『预约出价』。待该拍卖标的开始直播竞拍起，竞买人不得撤销该出价，但竞买人可继续在直播控制器中进行加价。加价幅度应与该场次规定的竞价阶梯一致，不得随意加价。
      实时出价：在直播竞拍场次，竞买人可在拟竞拍的拍卖标的正在直播竞拍时，在直播控制器中进行『实时出价』，实时出价不得撤销。加价幅度应与该场次规定的竞价阶梯一致，不得随意加价。
      委托出价：在委托代拍场次，竞买人可在系统规定的『委托出价』截止时间内，设置委托出价金额、增加委托出价或撤销委托出价。当委托出价时间截止，竞买人不能再加价或撤销委托出价。竞买人保证金交纳成功即视为出价成功，系统将以竞买人最终输入的最高委托出价金额为限自动按竞价阶梯代为出价，直至竞买成功或他人的出价超过竞买人设置的最高委托出价为止。竞买人须充分知悉并理解，我们不保证该次竞拍成功。
      落槌价：指在已结束的拍卖会中出价最高，且不低于拍卖公司规定的最低竞价或保留价，并且被本场拍卖会的拍卖师确认为成功竞得的出价。
      拍品款：竞拍成功后，买受人应支付的包括但不限于落槌价、佣金、证书费、平台服务费以及其它应由买受人支付的各项费用的总和。
      竞拍号牌：竞买人每参加一场拍卖会，系统都会为该竞买人自动生成用于该场拍卖会的竞拍号牌。一个竞拍号牌仅适用于一场拍卖会，竞买人若参于多个拍卖会，则将生成多个不同的竞拍号牌。 第四条：保证金条款和出价
      拍卖公司在焱十一开展的竞拍活动要求竞买人交纳相应保证金，竞买人按照拍卖公司的要求支付了足额的保证金后方可参与竞拍。
      保证金比例：保证金比例由拍卖公司规定，并在每场拍卖会的页面公布保证金比例。根据竞买人拟出价的金额，竞买人需要按照保证金比例交纳相应金额的保证金。例如，保证金比例为1／4（保证金／出价金额）时，竞买人若想出价人民币400元，则需要交纳人民币100元的保证金；若竞买人想出价人民币10000元，则需交纳人民币2500元的保证金；……以此类推，目前出价金额暂不设上限。保证金交纳成功即视为出价成功。保证金金额按四舍五入取整数计算。
      出价、保证金冻结与解冻： 3.1
      在直播竞拍场次中，竞买人可在两个环节进行出价。一，在竞买人拟竞拍的拍卖标的开拍前，竞买人可以在该拍卖标的的拍品详情页面中进行『预约出价』；二，在竞买人拟竞拍的拍卖标的正在直播竞拍时，竞买人可以在直播控制器中进行『实时出价』。
      3.2
      在竞买人拟竞拍的拍卖标的开拍前，竞买人的『预约出价』可以增加，也可以撤销；但如竞买人拟竞拍的拍卖标的已经开拍了，则无法再撤销出价，但竞买人仍可以在直播控制器中进行加价。竞买人的出价必须与该场次规定的竞价阶梯一致，不得随意加价。
      3.3
      直播竞拍场次的『预约出价』环节，竞买人的出价需按保证金比例交纳相应的保证金。当竞买人加价时，需要按保证金比例追加保证金。保证金将被冻结在竞买人『钱包』的『保证金』账户里。若竞买人未竞得该件拍卖标的，竞买人针对该拍卖标的在『预约出价』环节中交纳的保证金（包括在『预约出价』环节追加的保证金）将在该场拍卖会结束后解冻，无息转入竞买人『钱包』的『可用余额』中。如竞买人成功竞得该件拍卖标的，则竞买人针对该拍卖标的在『预约出价』环节中交纳的保证金（包括在『预约出价』环节追加的保证金）将在该件拍卖标的的订单结清后解冻。
      3.4
      直播竞拍场次的『实时出价』环节，竞买人应保证您『钱包』内的『可用余额』充足，避免因余额不足而影响竞拍过程。当竞买人进行『实时出价』时，系统会根据竞买人的出价金额和该场次的保证金比例，计算竞买人应交纳的保证金金额，并将该笔保证金从竞买人『钱包』的『可用余额』转入您『钱包』的『保证金』中进行冻结。如竞买人加价，则系统仍根据竞买人的加价金额计算应追加的保证金金额并转入竞买人『钱包』的『保证金』中进行冻结。当竞买人拟竞拍的拍卖标的拍卖结束后，如竞买人未竞得该件拍卖标的，则竞买人针对该拍卖标的在『实时出价』环节中交纳的保证金（包括在『实时出价』环节追加的保证金）将在该件拍卖标的结束后实时解冻，并无息转入竞买人『钱包』的『可用余额』内。如竞买人成功竞得该件拍卖标的，则竞买人针对该拍卖标的在『实时出价』环节交纳的保证金（包括在『实时出价』环节追加的保证金）将在该件拍卖标的的订单结清后解冻。
      3.5 在直播竞拍场次中，针对一件拍卖标的，如竞买人既有『预约出价』时交纳的保证金，又有在『实时出价』时交纳的保证金，则保证金的解冻时间按上述约定根据交纳环节的不同而分别处理。 3.6
      在委托代拍场次，竞买人在系统规定的『委托出价』截止时间内，可以增加或撤销出价。当『委托出价』时间截止，竞买人不能再加价或撤销。竞买人根据保证金比例交纳的保证金金额将在竞买人『钱包』的『保证金』账户内冻结，直至该场拍卖会结束。如竞买人未竞得该件拍卖标的，则系统会将您针对该拍卖标的而交纳的保证金无息转入竞买人『钱包』的『可用余额』内。如竞买人成功竞得该件拍卖标的，则竞买人针对该拍卖标的而交纳的保证金将在该件拍卖标的的订单结清后解冻。
      3.7
      竞买人在竞价过程中出价不得低于当前价，否则出价无效。竞买人在使用『预约出价』和/或『委托出价』时，如果竞买人设置的预约出价或委托出价金额低于拍卖标的保留价，则该出价无效。预约出价和委托出价每次仅可对一件拍卖标的使用。如果竞买人对多件拍卖标的进行预约出价或委托出价的，则须对每件拍卖标的单独设置预约出价或委托出价。在焱十一系统中，如两个或两个以上竞买人以相同的价格对同一拍卖标的设置了预约出价或委托出价，焱十一按照系统接收到出价的时间顺序认可第一个出价。如在焱十一的出价与其他参与拍卖的竞买人（包括其他网络平台的竞买人和拍卖会现场的竞买人）出价相同的情况下，由该场拍卖师按照接收到出价的时间顺序认可第一个出价。
      3.8 凡在竞买人『钱包』的『保证金』账户内的资金，均是被冻结的状态。保证金解冻是指保证金从竞买人『钱包』的『保证金』账户无息转入竞买人『钱包』的『可用余额』里。 扣除保证金（买受人违约情况下）：
      如买受人未在规定期限内足额支付拍品款，买受人需要按拍卖公司的规定承担违约责任。我们将按当时买受人参加该件拍卖标的竞拍时交纳的保证金比例，将买受人的保证金作为违约金进行扣除，例如，买受人参加该件拍卖标的竞拍时按１／４交纳的保证金，则我们扣除的保证金金额为该件拍卖标的落槌价的１／４，按四舍五入取整数计算。如被扣除的保证金不足违约金数额的，买受人应当补齐违约金。此外，我们及拍卖公司还有权视具体情况依照《拍卖规则》及相关法律的规定处分该拍卖标的，包括但不限于由拍卖公司对此件拍卖标的重新拍卖或退还给拍卖委托人。我们无需就代为扣除的保证金向买受人开具发票及收据等相关凭证。
      第五条：钱包、提现 用户保证严格遵守中国现行法律、法规、政府规章及其他应该遵守的规范性文件，不通过焱十一的钱包功能从事危害国家安全、洗钱、套现、传销等任何违法活动或者其他有违社会公共利益或公共道德的行为。
      钱包：用户可将自有资金转入到其焱十一帐号的『钱包』里，该笔资金不论是在『可用余额』内还是在『保证金』内，均不产生任何利息或收益，且用户知悉并同意自行完全承担货币贬值（汇率变化）等风险。
      提现：如果用户没有需要支付的拍品款，用户可以选择将『可用余额』内的资金进行提现，因“提现”而产生的手续费由您自行承担，手续费费率按各支付渠道标准执行。『提现』的资金将根据用户之前的支付路径原路转回。 第六条：用户的权利和义务
      用户应自行妥善保管用户名及密码，用户通过焱十一参与网络竞拍时只能使用本人的焱十一帐号（注册手机号），为方便用户在同一时间段能同时参与多个场次的竞拍，用户的焱十一帐号可以同时登录多个设备和终端，凡使用以用户自己的焱十一帐号（注册手机号）和密码登录后进行的操作，均视为用户本人的行为，用户应当对以其焱十一帐号（注册手机号)进行的所有行为承担法律责任。因用户个人原因导致帐户泄漏、因用户自身的操作而造成的损失，由用户自行承担该行为而直接或者间接导致的民事或刑事法律责任。如因此给艺得在线造成损失的，用户还应向艺得在线承担赔偿责任。
      用户通过焱十一参与竞拍，竞拍结果及相关法律责任由用户本人承担。在竞拍期间，用户的即时通讯工具所传达之竞买信息（无论是否为用户本人传达）均视为用户本人行为，由用户承担全部法律责任。
      除特别规定的拍卖方式之外，竞买人在竞价过程中的出价不得低于拍卖师认可的当前最高出价，也不得不按竞价阶梯出价，否则出价视为无效。
      在实时出价的情况下，竞买人承诺一旦通过焱十一出价，在任何情况下不得降低出价、撤回出价或撤销出价；在委托出价的情况下，竞买人承诺一旦通过焱十一出价，在规定的“委托出价”时间截止后，不得修改出价、撤回出价或撤销出价。竞买人、买受人对在竞买过程中出现的任何失误、错误须自行承担责任。
      当竞买人点击出价按钮后的出价金额被该场拍卖师认定为全场最高应价时，竞买人点击出价按钮的行为即被视为已签署成交确认书/拍卖笔录。
      在竞拍期间，竞买人不得妨碍其他竞买人参加竞买，不得与其他竞买人相互串通、恶意压价等。一经发现，我们有权拒绝其参加竞买或取消其竞买人、买受人资格，并追究相关法律责任。
      竞买人参与竞拍活动前应仔细查看竞拍活动页面对拍卖标的的描述介绍。焱十一上展示的关于拍卖标的的资料仅供竞买人参考使用，且竞买人知悉认可因摄影、显示等造成描述作品的色调等与原物有误差的，以原物为准。实地查看拍卖标的是竞买人的法定权利。如果竞买人（包括买受人）在拍卖标的展示的时间内未查看拍卖标的，视为其自愿放弃这一权利，愿意以拍卖标的的现状参与竞拍。竞买人（包括买受人）充分了解并自愿承担未实地查看拍卖标的的风险。因竞买人（包括买受人）未实地查看拍标的而产生的法律风险由竞买人（包括买受人）自行承担，竞买人（包括买受人）不得再以拍卖标的存在实物瑕疵为由提出异议。
      由于网络拍卖的拍卖标的大多数为特殊属性之商品，属于2014年3月15日起施行的《中华人民共和国消费者权益保护法》规定的“其他根据商品性质并经消费者在购买时确认不宜退货的商品，不适用无理由退货”。无退货保证的拍卖标的一经用户出价，即视为用户认可该拍卖标的一切现状与描述，以及您确认该拍卖标的属于不宜退货的商品。一旦成功竞得不得退货。
      竞买人通过焱十一成功竞得拍卖标的的，在领取最终竞得的拍卖标的时，应按拍卖规则完成拍卖标的的交易，即，买受人须在拍卖规则规定的期限内付清拍品款。如未按拍卖规则完成交易的，买受人应按拍卖规则承担相应责任及不利后果。
      竞买人知悉互联网竞拍活动数据处理的时间间隙，以及网络竞价的特殊性，有可能出现系统提示出价金额与最终落槌的出价金额不同、竞价无效等情况，如遇系统提示出价金额与最终落槌的出价金额不同的，竞买人认可以最终落槌的出价金额为准；如遇竞价无效等情况的，竞买人对此类情况知悉，并不会要求艺得在线承担责任。竞价无效的情况包括但不限于：拍卖活动现场和网络同时出现两个或两个以上同一金额的竞价时，而拍卖师拒绝网络竞买人的竞价；因网络延时导致竞买人的竞价在系统内显示已竞得，而实际却未竞得等情形。
      根据《中华人民共和国文物保护法》及其它法律、法规规定，允许带出中华人民共和国国境的拍卖标的，由买受人根据国家有关规定自行办理出境手续，焱十一不承担任何责任。 第七条：拍卖公司权利和义务
      本规则下的拍卖公司有在焱十一举办拍卖活动的权利，并根据合作合同享有由艺得在线提供的相关服务。
      参与竞拍活动的拍卖标的由拍卖公司提供，并由拍卖公司上传拍卖标的描述、图片、视频等。但下列标的不得在焱十一展示，否则由相应的拍卖公司承担一切法律后果。且，艺得在线有权采取必要措施，包括但不限于在不经提前告知的情况下将对应拍卖标的撤下或终止该场拍卖会：
      2.1 出土（水）文物； 2.2 以出土（水）文物名义宣传的复仿制品； 2.3 国有不可移动文物的附属构件； 2.4 国有文物购销经营单位收藏的珍贵文物； 2.5 损害国家利益或有可能产生不良社会影响的物品； 2.6
      被盗窃、盗掘、走私的文物或明确属于历史上被非法掠夺的中国流失文物； 2.7 涉嫌危害国家安全和损害民族利益的物品； 2.8 涉嫌丑化国家形象及政治人物的非主流艺术品； 2.9 带有黄色暴力等内容的物品； 2.10
      法律、法规等明令禁止买卖的物品； 2.11依法律、行政法规规定须经审批才能转让却未经审批的物品； 2.12 涉嫌欺骗的、假冒的或所有权不明晰的物品； 2.13 侵犯他人知识产权他人其他合法权益的物品； 2.14
      其他法律法规规定不得流通的物品。 拍卖公司在焱十一发布的拍卖标的的描述信息、文字、图片、图形、数据、文本、音乐、声音、录像、消息或其它材料，不管是否为公开表述，其真实性、准确性等均由拍卖公司负责。
      根据《中华人民共和国文物保护法》和国家有关规定限制出境的拍卖标的，拍卖公司应在拍卖标的的描述中注明。 拍卖公司有权自行制定拍卖规则、保证金金额、佣金比例、收费方式和拍卖标的交割方式等。
      在竞拍活动中，拍卖公司有权决定拍卖标的是否设定保留价以及如何确定保留价。无保留价是指该拍卖标的在竞价时不设定底价限制；有保留价是指该拍卖标的被拍卖公司设定了底价，在竞价过程中，只有出现等于或高于该拍卖标的的保留价的竞价时，才有可能成交。
      拍卖公司在拍卖标的描述中所列示的该拍卖标的的估价，只表明该拍卖公司对该拍卖标的所做的市场估值，不代表任何承诺。
      拍卖公司在拍卖标的预展和拍卖活动进行过程中，发现某特定拍卖标的存在法律瑕疵或明显的描述错误时，拍卖公司有权撤回该拍卖标的。
      因拍卖公司的操作而造成的损失，由拍卖公司自行承担由该行为而直接或者间接导致的民事或刑事法律责任。如因此给我们造成损失的，拍卖公司或竞买人还应向我们承担赔偿责任。
      拍卖公司应遵守中国的政策和法规，依法纳税，自觉杜绝利用平台之便利以任何形式进行任何违禁、违法或违规的货品或资金交易。拍卖公司因违反国家政策和法规造成的法律责任和经济损失由拍卖公司自行承担。如因此对我们造成损失的，我们保留追责的权利。
      拍卖公司不得利用焱十一操纵拍卖行为，一旦发现拍卖公司有操纵拍卖的行为，艺得在线有权立即终止与该拍卖公司的合作，并按约定追究拍卖公司的违约责任。 第八条：焱十一的权利和义务
      焱十一为网络技术服务平台，竞拍活动均由拍卖公司举办开展，焱十一尽力向竞买人和拍卖公司提供稳定的网络技术服务，使网络竞拍活动得以顺利进行。我们有权向买受人和拍卖公司收取服务费。
      竞买人和拍卖公司同意严格按照本规则及《焱十一用户注册协议》、《竞拍须知》、《免责声明》及《拍卖规则》、与支付相关的协议等同类文件的规定使用焱十一的服务。如有违反，我们有权取消用户的竞拍资格或终止拍卖公司的拍卖活动，由此产生的损失及不利后果由违反者自行承担。
      任何情形下，我们并非交易的参与方，既不是竞买人也不是拍卖公司。我们不对拍卖公司的任何口头、书面陈述或者上传的线上信息及拍卖标的的真实性、合法性做任何明示或暗示的担保，或对此承担任何责任。如因竞拍活动产生纠纷的，包括但不限于买受人未按时付款，或因拍卖公司原因导致买受人无法提取拍卖标的的，均由买受人和拍卖公司以自己的名义独立承担所有的法律责任。我们不参与对任何拍卖标的的描述、评估、鉴定和竞拍成功后的交割。
      通过焱十一成功竞得的拍卖标的的交割，包括拍卖标的的包装、运输、领取等一切事项，均在买受人和拍卖公司及包装运输服务提供方之间进行，我们不介入任何拍卖标的的交割事务。我们对拍卖公司或买受人选用的包装及运输服务提供方所造成的一切错误、遗漏、损坏或灭失等，不承担任何责任。如因因海关和/或检验检疫部门的规定以及国家相关的法律、法规、政策等原因造成竞得的拍卖标的无法顺利入境的，焱十一不承担任何责任。
      本规则下的竞拍服务将按“现状”和按“可得到”的状态提供，我们将在现有技术的基础上尽最大努力提供相应的安全措施以保障服务安全和正常运行。但由于可能存在的计算机病毒、网络通讯故障、浏览器兼容问题、系统维护等方面的因素以及可能发生的不可抗力，我们在此明确声明对技术服务不作任何明示或暗示的保证，包括但不限于：对服务的可适用性、没有错误或疏漏、持续性、准确性、可靠性、适用于某一特定用途。对此竞买人和拍卖公司予以理解并不应要求我们承担责任。
      我们有权根据本规则的规定，协助解决竞买人与拍卖公司在拍卖过程中可能产生的争议。
      如果买受人在拍卖公司规定的期限内未足额支付拍品款，拍卖公司可通过焱十一对买受人发起投诉，买受人将不能参与新的拍卖活动；而且，拍卖公司有权通过焱十一取消该买受人的竞拍资格。若买受人在付清拍品款后，拍卖公司未能在规定的期限内交付拍卖标的的，或是买受人在收到拍卖标的后，发现拍卖标的与描述严重不符，买受人可通过焱十一向拍卖公司发起投诉，一经查实，我们有权根据买受人的请求及与拍卖公司的合同约定，终止与该拍卖公司合作并追究违约责任。
      买受人、拍卖公司及包装运输服务提供方之间若发生争议或纠纷的，各方应尽量通过协商方式解决。协商不成时，当事各方均有权提起诉讼。焱十一除依法提供有关记录的数据外，不参与任何诉讼环节，不承担任何责任。
      对所有提供给焱十一的拍卖标的的资料（包括各类图片、文字、数据、影像等），我们享有在焱十一及其它境内外媒体、渠道上进行转载、广告、宣传等权利。所有拍卖标的资料的提供者，一旦向焱十一提供资料，即表示已认可并授权艺得在线行使此权利。
      焱十一所提供的拍卖公司网上预展、拍卖结果信息查询服务，目的在于更好地为拍卖公司及艺术品爱好者提供信息服务，所发布信息均来源于各拍卖公司，焱十一不对其真实性和准确性做任何明示或暗示的担保。
      焱十一的所有知识产权归北京艺得在线科技有限公司所有。未经艺得在线正式授权，任何单位及个人不得转载、摘编或以其它方式使用焱十一展示的所有作品。任何假冒、仿制本平台以及其它侵害本平台知识产权的行为，北京艺得在线科技有限公司保留追究其法律责任的权利
      第九条： 规则生效及适用 用户通过焱十一参与竞拍活动时，本规则即时生效，本规则生效后即表示您选择接受本规则，并同意接受本规则的全部内容。
      焱十一已经发布的或将来可能发布的各类规则、操作流程均为本规则不可分割的一部分，与本规则具有同等法律效力。我们有权根据需要不时地制定、修改本规则、附件或其他各类规则、操作流程，如有任何变更，将在焱十一公布。任何修订和新规则及流程一经公布即自动生效，成为本规则的一部分。如您继续在焱十一参与竞拍活动，则视为您对修改后的条款不持异议并同意遵守。
      第十条：法律适用与争议解决 本规则适用中华人民共和国法律。 因本规则产生的任何争议，由各方协商解决，协商不成的，任何一方有权向北京市经济技术开发区人民法院提起诉讼。 2021年12月25日</p>
  </div>
</template>
<script>
export default {
  name: 'ServiceRule'
}
</script>
<style lang="less" scoped>
div {
  padding: 20px 80px;
  p {
    text-align: justify;
    text-indent: 2em;
    font-size: 15px;
  }
}
</style>
